<template>
  <div class="wysiwyg">
    <h3>工具指示</h3>

    <table class="table border-collapse table-auto w-full text-sm">
      <thead>
        <th class="w-16">項次</th>
        <th class="text-left">說明</th>
        <th class="w-16">圖示</th>
      </thead>
      <tbody>
        <tr v-for="(item, i) in icons" :key="item.id">
          <td class="text-center">{{ i + 1 }}</td>
          <td>{{ item.text }}</td>
          <td><img class="w-8 black" :src="`${item.svg}`" alt="" /></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { reactive } from 'vue';
export default {
  setup() {
    const icons = reactive([
      {
        text: '按著滑鼠左鍵拖曳，可上下左右旋轉鏡頭欣賞；按著滑鼠右鍵可平移觀看。',
        id: 'exhibitdrag',
        svg: require('@/assets/exhibitdrag.svg'),
      },

      {
        text: '使用滑鼠滾輪，可放大縮小鏡頭欣賞',
        id: 'zoom-in',
        svg: require('@/assets/zoom-in.svg'),
      },
    ]);
    return { icons };
  },
};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
